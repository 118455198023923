import React from 'react';
import style from './RecognizeStage.module.css';
import Container from '../Container/Container';
import { DocumentInfo } from '../../@types/api';
import RecognizeStageItem from './RecognizeStageItem';
import { StartRecognizePayload } from '../../store/types';

export interface RecognizeStageProps {
  documents: DocumentInfo[];
  onRecognize: (payload: StartRecognizePayload) => void;
  onReset: () => void;
}

export default function RecognizeStage({
  documents,
  onReset,
  onRecognize,
}: RecognizeStageProps) {
  return (
    <div className={style.root}>
      <Container noMaxWidth>
        {documents.map((item: DocumentInfo) => (
          <RecognizeStageItem
            isLoading={item.isRecognizeLoading}
            progress={item.recognizeProgress}
            key={item.id}
            documentInfo={item}
            onRecognize={onRecognize}
          />
        ))}
      </Container>
    </div>
  );
}
